import { 
    Box, Button, Drawer, DrawerBody, DrawerContent, 
    DrawerHeader, DrawerOverlay, HStack, IconButton, 
    Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, 
    SimpleGrid, Text, useDisclosure, VStack 
} from "@chakra-ui/react"
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import Link from "../views/Link"
import Footer from './Footer'
import Header from './header'
import NavBar from './header/NavBar'
import { useEffect } from "react"


const PageBody = ({navType, children, excludeHeader, excludeFooter, className, headerProps, footerProps, ...props}) => {
    const { t } = useTranslation('common')
    const { isOpen, onOpen, onClose } = useDisclosure()

    const nav = excludeHeader? null : navType == PageBody.vars.NAV_TYPES.home? <Header banner={{
        primaryText: t('site-desc'),
        search: {
          placeholder: t('header:search-placeholder'),
          recentSearchesText: t('header:recent-searches')
        }
    }} {...(headerProps || {})} /> : <NavBar {...(headerProps || {})} />
    

    const closePolicy = () => {
        //Log the close so the dialog doesn't show again for this user
        localStorage.setItem("cookiePolicyAccepted", "yes")
        onClose()
    }

    useEffect(() => {
        if(!localStorage.getItem("cookiePolicyAccepted")) {
            onOpen()
        }
    }, [])
    return(
        <Box>
            {nav}
            <Box as="main" className={`container ${className || ''}`} {...props}>
                {children}
            </Box>
            {!excludeFooter? <Footer {...(footerProps || {})} /> : null}
            <Drawer
                isOpen={isOpen}
                placement='bottom'
                onClose={closePolicy} closeOnOverlayClick={false}
            >
                <DrawerOverlay />
                <DrawerContent bg="rgba(112,63,185,1)">
                    <DrawerBody>
                        <VStack alignItems="center" w="100%">
                            <Text w="100%" color="#fff !important" textAlign="center" 
                            fontSize={{base: "12px", md: "14px"}} fontWeight="400">
                                <Trans i18nKey="footer:cookies-warning"
                                components={[
                                <Link 
                                href="/privacy" 
                                color="#fff !important" 
                                fontWeight="bold"
                                cursor="pointer" 
                                textDecoration="underline"
                                outline="none !important" 
                                _focus={{outline: "none !important"}} />]} />
                            </Text>
                            <Box as="button" aria-label="Ok"
                                className="btn btn-success btn-sm text-cap" 
                                borderColor="#FF10A0 !important" 
                                style={{
                                    minWidth: "100px", color: "#fff !important", marginTop: "1rem"
                                }}
                                onClick={closePolicy}>
                                {t('ok')}
                            </Box>
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    )
}

PageBody.vars = {
    NAV_TYPES: {
        home: "home",
        others: "others"
    }
}

export default PageBody
