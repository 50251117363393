import { Box, Flex } from "@chakra-ui/react"
import useTranslation from "next-translate/useTranslation"
import { FACEBOOK_PAGE_LINK, INSTAGRAM_PAGE_LINK, TWITTER_PAGE_LINK } from "../utils/constants"
import Link from "../views/Link"
import { 
  FaLock, FaClock, FaCheckSquare
} from "react-icons/fa"


export default function Footer({...props}) {
    const { t } = useTranslation('footer')

    return(
        <Box as="footer" style={{width: "100%"}} {...props}>
            <Box as="div" className="footer" w="100%" px={{base: "20px", md: "20%"}}>
                <div className="footer-title">{t('list-ad')}</div>
                <div className="footer-grids">
                    <div className="footer-grid">
                        <FaCheckSquare size="4rem" />
                        <div style={{marginRight: "10px"}}>{t('easy')}</div>
                    </div>
                    <div className="footer-grid">
                        <FaClock size="4rem" />
                        <div>{t('fast')}</div>
                    </div>
                    <div className="footer-grid">
                        <FaLock size="4rem" />
                        <div>{t('secure')}</div>
                    </div>
                </div>
            </Box>
            <div className="row footer-color-grid-wrapper">
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
                <div className="col-1 footer-color-grid"></div>
            </div>
            <div className="footer-links-wrapper container">
                <div className="footer-links-external">
                    <a target="_blank" href={FACEBOOK_PAGE_LINK} title="Facebook" aria-label="Facebook">
                        <i className="fa fa-2x fa-facebook"></i>
                    </a>
                    <a target="_blank" href={INSTAGRAM_PAGE_LINK} title="Instagram" aria-label="Instagram">
                        <i className="fa fa-2x fa-instagram"></i>
                    </a>
                    <a target="_blank" href={TWITTER_PAGE_LINK} title="Twitter" aria-label="Twitter">
                        <i className="fa fa-2x fa-twitter"></i>
                    </a>
                </div>
                <div className="footer-links-internal container">
                    <Flex className="footer-links-internal-box" flexWrap="wrap" 
                        fontSize={{base: "14px", md: "16px"}}>
                        <Box w={{base: "50%", md: "25%"}} mb="25px">
                            <Link href="/about" m="0px !important">
                                {t("about-us")}
                            </Link>
                        </Box>
                        <Box w={{base: "50%", md: "25%"}} mb="25px">
                            <Link href="/contact" m="0px !important">
                                {t("contact-us")}
                            </Link>
                        </Box>
                        <Box w={{base: "50%", md: "25%"}} mb="25px">
                            <Link href="/privacy" m="0px !important">
                                {t("privacy-policy")}
                            </Link>
                        </Box>
                        <Box w={{base: "50%", md: "25%"}} mb="25px">
                            <Link href="/tos" m="0px !important">
                                {t("tos")}
                            </Link>
                        </Box>
                    </Flex>
                </div>
                <Flex display="none !important" flexDirection="row" flexWrap="wrap" justifyContent="center" style={{display: "none !important"}} alignItems="center" width="100%">
                    <div>
                        Built By
                    </div>
                    <Flex flexDirection="column" justifyContent="center" alignItems="center" as="a" href="https://feyitech.com" target="_blank" style={{color: "#703fb9", display: "block", textAlign: "center"}}>
                        <div>
                            Feyitech Digital Lane
                        </div>
                    </Flex>
                </Flex>
            </div>
        </Box>
    )
}
