"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/defineProperty"));

var _useTranslation2 = _interopRequireDefault(require("next-translate/useTranslation"));

var _react = require("react");

var _swr = _interopRequireDefault(require("swr"));

var _functions = require("../utils/functions");

var _useFetch3 = require("./useFetch");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var queryString = require('query-string');

var axios = require('axios')["default"];

var hasThese = function hasThese(supplied, requirements) {
  var _requirements$require, _requirements$require2, _requirements$require3, _requirements$require4;

  var queries = supplied ? Object.keys(supplied) : [];

  if (requirements && queries.length == 0 && ((requirements === null || requirements === void 0 ? void 0 : (_requirements$require = requirements.requiresPayloadOr) === null || _requirements$require === void 0 ? void 0 : _requirements$require.length) > 0 || (requirements === null || requirements === void 0 ? void 0 : (_requirements$require2 = requirements.requiresPayloadAnd) === null || _requirements$require2 === void 0 ? void 0 : _requirements$require2.length) > 0)) {
    return false;
  }

  var hasRequirement = true; //check OR Reqirements

  if ((requirements === null || requirements === void 0 ? void 0 : (_requirements$require3 = requirements.requiresPayloadOr) === null || _requirements$require3 === void 0 ? void 0 : _requirements$require3.length) > 0) {
    var has = false;

    for (var qIndex in queries) {
      if (requirements.requiresPayloadOr.includes(queries[qIndex]) && !(0, _functions.notSet)(supplied[queries[qIndex]])) {
        has = true;
        break;
      }
    }

    hasRequirement = has;
  } //check AND Reqirements


  if ((requirements === null || requirements === void 0 ? void 0 : (_requirements$require4 = requirements.requiresPayloadAnd) === null || _requirements$require4 === void 0 ? void 0 : _requirements$require4.length) > 0) {
    var has = true;

    for (var qIndex in queries) {
      if (!requirements.requiresPayloadAnd.includes(queries[qIndex]) && !(0, _functions.notSet)(supplied[queries[qIndex]])) {
        has = false;
        break;
      }
    }

    hasRequirement = has;
  }

  return hasRequirement;
};

var hasSupplies = function hasSupplies(options) {
  if (!options) return false;
  var payload = options.payload,
      path = options.path,
      locale = options.locale,
      requirements = options.requirements;
  return !(!payload && requirements !== null && requirements !== void 0 && requirements.payloadRequired || (0, _functions.textEmpty)(path) && requirements !== null && requirements !== void 0 && requirements.pathRequired || (0, _functions.textEmpty)(locale) && requirements !== null && requirements !== void 0 && requirements.localeRequired);
};

var getUrl = function getUrl(key, apiPath, payload, locale) {
  payload = (0, _functions.notSet)(payload) || !(typeof payload === "object") ? {
    locale: locale ? locale : ""
  } : _objectSpread(_objectSpread({}, payload), {}, {
    locale: locale ? locale : ""
  });

  if ((0, _functions.textEmpty)(apiPath)) {
    apiPath = "";
  } else {
    if (!apiPath.startsWith("/")) apiPath = "/".concat(apiPath);
    if (apiPath.endsWith("/")) apiPath = apiPath.substring(0, apiPath.length - 1);
  }

  var url = "/api/v1/".concat(key).concat(apiPath, "?").concat(queryString.stringify(payload));
  console.log("getUrl", url);
  return url;
};

var useApiGet = function useApiGet(key, locale, options) {
  var payload = options.payload,
      path = options.path,
      requirements = options.requirements;
  var initialData = (options === null || options === void 0 ? void 0 : options.initialData) || null;
  var initialState = {
    payload: payload,
    path: path,
    requirements: requirements
  };

  var _useState = (0, _react.useState)(initialState),
      state = _useState[0],
      setState = _useState[1];

  var _useState2 = (0, _react.useState)(true),
      updateNow = _useState2[0],
      setUpdateNow = _useState2[1];

  var load = hasSupplies(state) && hasThese(state.payload, state.requirements);
  console.log("HAS_REQ:", load, hasThese(state.payload, state.requirements), state.payload, state.requirements);

  var _useTranslation = (0, _useTranslation2["default"])(),
      lang = _useTranslation.lang;

  var _useState3 = (0, _react.useState)(),
      returnData = _useState3[0],
      setReturnData = _useState3[1];

  var _useFetch = (0, _useFetch3.useFetch)(load ? getUrl(key, state.path, state.payload, options !== null && options !== void 0 && options.noLocale ? null : lang) : null, initialData),
      _useFetch2 = (0, _slicedToArray2["default"])(_useFetch, 4),
      data = _useFetch2[0],
      error = _useFetch2[1],
      status = _useFetch2[2],
      updateUrl = _useFetch2[3];

  (0, _react.useEffect)(function () {
    if (data) {
      if (options !== null && options !== void 0 && options.responseFilter) {
        setReturnData(options.responseFilter(data));
      } else {
        setReturnData(data);
      }
    }
  }, [data]);

  var commit = function commit(newState) {
    setState(_objectSpread(_objectSpread({}, state), newState));
    setUpdateNow(!updateNow);
  };

  (0, _react.useEffect)(function () {
    var load = hasSupplies(state) && hasThese(state.payload, state.requirements);
    if (!load) return;
    apiLog("CLIENT", "UPDATEX", getUrl(key, state.path, state.payload, options !== null && options !== void 0 && options.noLocale ? null : lang));
    updateUrl(getUrl(key, state.path, state.payload, options !== null && options !== void 0 && options.noLocale ? null : lang));
  }, [updateNow, lang]);
  return [returnData, error, status, commit, setReturnData];
};

var GetCache = {};

var apiGet = function apiGet(key, json, locale) {
  var queries = queryString.stringify(_objectSpread(_objectSpread({}, json || {}), {}, {
    locale: locale
  }));
  var url = "/api/v1/".concat(key, "?").concat(queries);
  return new Promise(function (resolve, reject) {
    if (GetCache[url]) {
      console.log("hasCache:", true);
      resolve(GetCache[url]);
    } else {
      axios.get(url).then(function (r) {
        GetCache[url] = r;
        resolve(r);
      })["catch"](function (e) {
        reject(e);
      });
    }
  });
};

var apiGetJson = apiGet;

var apiPost = function apiPost(key, json, locale) {
  return axios.post("/api/v1/json-".concat(key, "?locale=").concat(locale), json, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

var apiPostJson = apiPost;

var apiPostFileJson = function apiPostFileJson(key, files, json, locale, onUploadProgress) {
  var formData = new FormData();
  files === null || files === void 0 ? void 0 : files.forEach(function (file) {
    formData.append("all", file);
  });
  formData.append("json", JSON.stringify(json));
  return axios.post("/api/v1/file-json-".concat(key, "?locale=").concat(locale), formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: onUploadProgress
  });
};

var API_REQUEST_STATUS = {
  idle: 'idle',
  fetching: 'fetching',
  fetched: 'fetched',
  error: 'error'
};
var useApiOptions = {
  debug: true
};

function apiLog() {
  if (useApiOptions.debug) {
    console.log(Array.prototype.slice.call(arguments));
  }
}

module.exports = {
  useApiGet: useApiGet,
  apiGet: apiGet,
  apiGetJson: apiGetJson,
  apiPost: apiPost,
  apiPostJson: apiPostJson,
  apiPostFileJson: apiPostFileJson,
  apiLog: apiLog,
  API_REQUEST_STATUS: API_REQUEST_STATUS
};